export const fiches_pap_en = {
  analyse: 'Analysis',
  engagement: 'Engagement',
  eval_pertes: 'Eval. Losses',
  documents: 'Documents',
  paiements: 'Payments',
  ok: 'Ok',
  annuler: 'Cancel',
  evaluation: 'Evaluation',
  numero: 'N°',
  feuille_de_bien: 'Property sheet',
  contrat: 'Contract',
  supprimer: 'Delete',
  erreur_acces_fichier: 'File access error',
  type: 'Type',
  nom: 'Name',
  quantite: 'Quantity',
  montant: 'Amount',
  description: 'Description',
  type_de_bien: 'Type of property',
  type_de_perte: 'Type of loss',
  en_nature: 'In kind',
  genre: 'Type',
  date_naissance: 'Date of birth',
  localite: 'Locality',
  telephone: 'Telephone',
  email: 'Email',
  creer_le_contrat: 'Create the contract',
  uploader_le_contrat_signe: 'Upload the signed contract',
  le_contrat_a_ete_cree: 'The contract was created',
  selectionnez_un_pap: 'Select a Stakeholder',
  creer_un_pap: 'Create a Stakeholder',
  isPap: 'Is a PAP',
  cni: 'CNI',
  reference: 'Reference',
  code: 'Code',
  prenom: 'First name',
  surnom: 'Nick name',
  identite: 'IDENTITY',
  socio_eco: 'SOCIO-ECO',
  compensation: 'COMPENSATION',
  categorie: 'Category',
  numero_enquete: 'Packaged number',
  inventaires: 'Inventories',
  parcelles: 'Plots',
  proxies: 'Proxies',
  is_proxy_of: 'Is proxy of',
  is_proxy_by: 'Is proxied by',
  personne_affectee_par_le_projet: 'Person affected by the project',
  partie_prenante: 'Stakeholders',
  activite_principale: 'Main activity',
  revenu_moyen: 'Average income',
  statut: 'Status',
  nombre_membre_menage: 'Number Member Household',
  vulnerable: 'Vulnerable',
  signer: 'Sign',
  signature: 'Signature',
  fichier_signe: 'Signed file',
  selectionnez_votre_fichier: 'Select your file',
  signature_importee: 'Imported signature',
  erreur_upload_signature: 'Error uploading signature',
  ajouter_quittance: 'Add Receipt',
  fichier_quittance: 'Receipt file',
  quittance_enregistree: 'Receipt saved',
  regle_le: 'Payed on',
  quittance: 'Receipt',
  activites: 'Activities',
};
